import phone from "../../images/phone-icon.jpg";
import shiv_mandir from "../../images/suresh_chuhanke.png";
import devki_nandan from "../../images/devki_nandan.png";
import dipankar_ji from "../../images/dipankar_ji.png";
import "./contact.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, Alert } from 'react-bootstrap';


const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    AOS.init();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.username.trim()) newErrors.username = 'Username is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
    if (!formData.password.trim()) newErrors.password = 'Password is required';

    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      console.log(formData); // Handle form submission, e.g., send to an API

      setSuccessMessage('Form submitted successfully!');
      setFormData({
        username: '',
        email: '',
        password: '',
      });
    }
  };

    return (
      <div>

      <div className="main-contactus">

        <div className="contactus-section" >
        <img src={devki_nandan}  alt="location" />
          <p>पुज्य देवकी नंदन ठाकुर जी</p>
          <h1>सनातन बोर्ड बने</h1>
        </div>
        
        
        <div className="contactus-section"  >
        <img src={shiv_mandir}  alt="phone" />
        <p>सुरेश चव्हाणके जी</p>
        <h1>जनसंख्या नियंत्रण हो</h1>
       </div>
       
        <div className="contactus-section">
        <img src={dipankar_ji}  alt="email" />
        <p>पुज्य दीपंकर जी महाराज</p>
        <h1>सभी हिंदू एक हों</h1>
        </div>

      </div>

    <h1 style={{ textAlign: 'center', fontSize:'22px'}}>
    किसी भी प्रश्न और सुझाव के लिए
     </h1>             
    <Container style={{marginBottom: '80px;'}} >
    <Card>
    <Card.Header className="text-center" style={{padding: '50px;'}}>सुझाव दें</Card.Header>
    <Card.Body  className="enquiryform">
    {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Form onSubmit={handleSubmit} >
        <Form.Group controlId="formUsername">
          <Form.Label>Name</Form.Label>
          <Form.Control  type="text" name="username" value={formData.username} onChange={handleInputChange}  isInvalid={!!errors.username}
          placeholder="Enter username" />
         <Form.Control.Feedback type="invalid">
           {errors.username}
           </Form.Control.Feedback>
         </Form.Group><br />

        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" name="email"  value={formData.email}  onChange={handleInputChange}  isInvalid={!!errors.email} placeholder="Enter email" />
          <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
        </Form.Group><br />

        <Form.Group controlId="formPassword">
          <Form.Label>Suggestion</Form.Label>
          <Form.Control
         as="textarea"
         rows={4} // Number of rows you want for the suggestion box
         name="password"
         value={formData.password}
         onChange={handleInputChange}
         isInvalid={!!errors.password}
         placeholder="Enter your suggestion"
        />
        <Form.Control.Feedback type="invalid">
                {errors.suggestion}
          </Form.Control.Feedback>
        </Form.Group><br />

        

     <Button variant="primary" type="submit">
          Contact Us
        </Button>
      </Form>
      </Card.Body>
        </Card>
    </Container>
   </div> 

 
        
      );
};

export default RegistrationForm;
