import Reactjs from "../../../images/react.png";
import Laravel from "../../../images/laravel.png";
import Node from "../../../images/node.png";
import "./website.css";
import SignUp from '../../SignupForm/signup'; // Adjust path based on actual directory structure
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import React, { useState, useEffect } from 'react';
import gurukul from "../../../images/gurukul.png";
import safe_future from "../../../images/safe-future.jpg";
import dhram_raksha from "../../../images/ravan_mra.png";

export const AboutUsComponent = () => {
    
};


const AboutUs = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };
    useEffect(() => {
      AOS.init();
  }, []);
  
    return (

        
        <div className="main-website">
           <div className="first-website-row">
        <div className="website-section" data-aos="fade-right" data-aos-duration="1000">
            <h3>गुरुकुल खुलने के फायदे</h3>
            <h1 className="text-big" style={{fontSize: '50px'}}>
            गुरुकुल में मुफ्त शिक्षा
                   </h1>
                   <p className="text-small" style={{fontSize: '17px', fontStyle: 'oblique'}}>               
                   <h6> जो परिवार गरीब हैं या जिन बच्चों की पैसों के अभाव में पढ़ाई छूट जाती है, वे सभी गुरुकुल में निशुल्क पढ़ाई कर सकते हैं</h6>
                   </p>
              <button type="button" className="btn btn-primary" onClick={openModal}>सुझाव दें</button>
       </div>
       <div className="website-img-section">
       <img src={gurukul}  alt="Reactjs" data-aos="fade-left" data-aos-duration="1000" />
       </div>
       </div>
       
       {/* <div style={{ marginTop: '200px' }}> */}
       <div className="first-website-row">
       <div className="website-img-section">
       <img src={safe_future}  alt="laravel" data-aos="fade-right" data-aos-duration="1000"/>
       </div>
       <div className="website-section" data-aos="fade-left" data-aos-duration="1000">
               <h1 className="text-big" style={{fontSize: '50px'}}>
                 सुरक्षित  भविष्य 
                   </h1>
                   <p className="text-small" style={{fontSize: '17px', fontStyle: 'oblique'}}>               
                   <h6> गुरुकुल से पढ़ाई करके डिग्री लेने के बाद आप मंदिर में पुजारी के रूप में काम कर सकते हैं</h6> 
                   <h6>  मंदिर के पुजारी के रूप में धर्म के लिए काम करने पर आप सैलरी प्राप्त कर सकते हो
                   </h6>
                   </p>
                   <button type="button" className="btn btn-primary" onClick={openModal}>सुझाव दें</button>
       </div>
       </div>
       {/* </div> */}
       <div className="first-website-row">
       <div className="website-section" data-aos="fade-right" data-aos-duration="1000">
       <h1 className="text-big" style={{fontSize: '50px'}}>
              धर्म की सुरक्षा
                   </h1>
                   <p className="text-small" style={{fontSize: '17px', fontStyle: 'oblique'}}>               
                   <h6>सनातन का ज्ञान होने पर आप,  सनातन के खिलाफ यदि कोई गलत बोलता है या हमारे देवी-देवताओं का अपमान करता है तो आप उचित कदम उठा सकते हो</h6>
                   </p>
                  
                   <button type="button" className="btn btn-primary" onClick={openModal}>सुझाव दें</button>
       </div>
       <div className="website-img-section">
       <img src={dhram_raksha}  alt="node" data-aos="fade-left" data-aos-duration="1000"/>
       </div>
       </div>
       {showModal && <SignUp closeModal={closeModal} />}
       </div>
      );
};

export default AboutUs;
