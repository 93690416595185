import React, { useState } from 'react';
import "./footer.css";
import webcuts from "../../images/webcuts.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import mandir from "../../images/mandir.png";
import logo from "../../images/logo.png";

const Footer = () => {

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (value === '') {
      setError('Email address is required.');
    } else if (!validateEmail(value)) {
      setError('Invalid email address format.');
    } else {
      setError('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateEmail(email)) {
      setSuccess('Subscription successful!');
      setError('');
    } else {
      setSuccess('');
      setError('Invalid email address format.');
    }
  };

  return (
    <footer className="footer">
      <div className="footer-main">
        <div className="footer-section-logo">
          <h2 className="text-footer">
            <img src={logo} className="footer-logo" alt="Webcuts Solution Logo" />
            <span className="footer-brand">सनातन बोर्ड</span>
          </h2>
          <p className="footer-description">
          सनातन बोर्ड का लक्ष्य मंदिरों को सरकार से मुक्त करना है, और बड़े मंदिरों में आने वाले दान से गुरुकुल खोलना है, साथ ही पंडितों को वेतन देना। इसके अलावा, सभी बड़े मंदिरों को छोटे मंदिरों से जोड़ना 
          </p>
        </div>
        <div className="footer-section-company">
          <h2 className="text-footer">सनातन बोर्ड</h2>
          <ul className="footer-links">
            <li><Link to="/" className="footer-link">सनातन बोर्ड</Link></li>
            <li><Link to="/problems" className="footer-link">समस्याएँ</Link></li>
            <li><Link to="/solution" className="footer-link">समाधान</Link></li>
            <li><Link to="/needs" className="footer-link">आवश्यकता</Link></li>
          </ul>
        </div>
        <div className="footer-section-service">
          <h2 className="text-footer">सनातन बोर्ड के फायदे</h2>
          <ul className="footer-links">
            <li><Link to="/temple" className="footer-link">टैक्स मुक्त मंदिर </Link></li>
            <li><Link to="/pujari" className="footer-link">पुजारी का कार्य</Link></li>
            <li><Link to="/gurukul" className="footer-link">गुरुकुल</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h2 className="text-footer">सनातन बोर्ड का उद्देश्य</h2>
          <ul className="footer-links">
            <li className="footer-link">सनातन बोर्ड का उद्देश्य राजनीति से दूर रहकर सनातन धर्म के लिए काम करना है, चाहे सरकार किसी भी पार्टी की क्यों न हो</li>
            <li className="footer-link"></li>
            <li className="footer-link">info@sanatan-board.com</li>
          </ul>
          <form onSubmit={handleSubmit} className="footer-form">
            <div className="form-group">
              <input
                type="text"
                name="search"
                placeholder="Enter Email Address"
                className="form-control"
                value={email}
                onChange={handleChange}
              />
              <button type="submit" className="subscribe-button">सनातन से जुड़े</button>
            </div>
          </form>
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}

          <div className="social-icons">
            <a href=""  rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href=""  rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a href="" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href=""  rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
            <a href=""  rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
