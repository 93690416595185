import React, { useState, useEffect } from 'react';
import aboutus from "../../images/Untitled design.png";
import terririst from "../../images/teririst.png";
import chota_pandit from "../../images/bolywood.png";
import "./about.css";
import SignUp from "../SignupForm/signup";
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const AboutUs = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    AOS.init();
}, []);

  return (
    <div className="main-aboutus">
      <section className="aboutus-section" data-aos="fade-right" data-aos-duration="2000">
        <h1>समस्याएँ</h1>
         <h1 className="text-big" style={{ fontSize: '30px' }}>
             फिल्मों के द्वारा हिंदू धर्म का अपमान
        </h1>
        <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
          फिल्मों में पंडित को हमेशा पागल या कॉमेडियन दिखाया जाता है, जबकि मुसलमान को सीधा, सच्चा और मासूम दिखाया जाता है। 
          </p>
           <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
          हिंदू लड़कियों को छोटे कपड़ों में और मुस्लिम लड़कियों को बुरके में दिखाया जाता है।
          </p>
          <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
          बॉलीवुड में अक्सर लड़की हिंदू होती है और लड़का किसी अन्य धर्म का।
        </p>
        <button type="button" className="btn btn-primary" onClick={openModal}>सुझाव दें</button>
      </section>

      {showModal && <SignUp closeModal={closeModal} />}

      <section className="specification-section">
        <img src={chota_pandit} alt="aboutus" data-aos="fade-left" data-aos-duration="2000" />
      </section>

      <div className="ourmission-section">
        <h1 style={{ textAlign: "center", color: "blue" }}>कट्टरवाद और आतंकवाद पर धर्मगुरु और कथा वाचकों का चुप रहना</h1>
        <p style={{ fontSize: '25px', fontStyle: 'fantasy' }}  data-aos="fade-right" data-aos-duration="2000">
        कट्टरवादी और आतंकवादी लगातार देश को बांटने की बात करते हैं, जबकि सनातन के धर्मगुरु केवल कथा करने में व्यस्त रहते हैं।
        मंदिरों में, जागरणों में, शोभायात्राओं में केवल भगवान की पूजा-पाठ, लेकिन सनातन धर्म की रक्षा और सनातनियों की एकता के लिए कोई काम नहीं होता
        </p>
        <img src={terririst} alt="mission" data-aos="fade-left" data-aos-duration="2000" />
        <p style={{ fontSize: '25px', fontStyle: 'fantasy' }} data-aos="fade-right" data-aos-duration="2000">
        कावड़ यात्रा में डीजे साउंड का आपस में compitition होने पर धर्मगुरुओं और कथा वाचकों का चुप रहना।
        तिरुपति बालाजी मंदिर के प्रसाद में मिलावट पर धर्मगुरुओं और कथा वाचकों का चुप रहना।
        हिंदू विहीन होते मंदिरों पर धर्मगुरुओं और कथा वाचकों का चुप रहना।
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
