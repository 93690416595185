import Wordpress from "../../../images/wordpress.png";
import Shopify from "../../../images/shopify.png";
import "./ecommerce.css";
import SignUp from "../../SignupForm/signup";
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import mandir from "../../../images/mandir.png";
import pujari from "../../../images/pujari.png";


const AboutUs = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };
    useEffect(() => {
      AOS.init();
  }, []);
  
    return (

        
        <div className="main-ecommerce">
              <div className="first-ecommerce-row">
        <div className="ecommerce-section" data-aos="fade-right" data-aos-duration="1000">
            <h3>मंदिर के पुजारी का सनातनियों के लिए काम</h3>
            <h1 className="text-big" style={{fontSize: '50px'}}>
            कोलनी और गांव में होने वाली सनातन विरोधी गतिविधियां राष्ट्रीय स्तर तक जाएगी
                   </h1>
                   <p className="text-small" style={{fontSize: '17px', fontStyle: 'oblique'}}>               
                    <h6>सनातन विरोधी गतिविधियों जैसे बढ़ती जनसंख्या, धर्मांतरण, जातिगत विभाजन, और मंदिरों की सुरक्षा के मामलों में भी
                       सभी को संगठित करना और इसकी जानकारी सभी मंदिरों तक और सनातन बोर्ड के पुजारियों तक पहुँचानी</h6>
                   </p>
                   <button type="button" className="btn btn-primary" onClick={openModal}>सुझाव दें</button>
          </div>
       <div className="ecommerce-img-section">
       <img src={mandir}  alt="Wordpress" data-aos="fade-right" data-aos-duration="1000"/>
       </div>
       </div>

       {/* <div style={{ marginTop: '200px' }}> */}
       <div className="first-ecommerce-row">
       <div className="ecommerce-img-section">
       <img src={pujari}  alt="Shopify" data-aos="fade-left" data-aos-duration="1000" />
       </div>
       <div className="ecommerce-section" data-aos="fade-left" data-aos-duration="1000">
                  <h1 className="text-big" style={{fontSize: '50px'}}>
                    हवन, पूजा-पाठ, कथा सभी निःशुल्क
                   </h1>
                   <p className="text-small" style={{fontSize: '17px', fontStyle: 'oblique'}}>               
                   <h6>शादी के समय होने वाले फेरे में पूजा, हवन और मरने के समय होने वाली पूजा उस क्षेत्र के पुजारी द्वारा निःशुल्क होनी चाहिए, ताकि किसी घर में मृत्यु होने पर उस पर आर्थिक प्रभाव न पड़े।
                   और आवश्यकता पड़ने पर मंदिर के दान से उस परिवार की मदद कर सके।</h6>
                   </p>
                   <button type="button" className="btn btn-primary" onClick={openModal}>सुझाव दें</button>
       </div>
       </div>
       {/* </div> */}
       {showModal && <SignUp closeModal={closeModal} />}
       </div>
      );
};

export default AboutUs;
